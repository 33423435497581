$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass('show');


    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
        $('.dropdown-submenu .show').removeClass("show");
    });


    return false;
});

function showMenu() {
    $('.navbar-toggler-icon').on('click', function (e) {
        if ($('.navbar-toggler').attr('aria-expanded') == 'true') {
            $('#overlay').removeClass('overlay--active');
            $('.body-wrapper').removeClass('scroll-disable');
            $('html').removeClass('html-scroll-disable');

        } else {
            $('#overlay').addClass('overlay--active');
            $('.body-wrapper').addClass('scroll-disable');
            $('html').addClass('html-scroll-disable');
        }
    });
}

showMenu();

//слайдер на главной

$('.slider').on('init', function () {
    $('.slick-active').prev().addClass('prev-slide-item');
    $('.slick-active').next().addClass('next-slide-item');
})
$('.slider').on('beforeChange', function () {
    $('.slick-slide').removeClass('prev-slide-item next-slide-item');
})
$('.slider').on('afterChange', function () {
    $('.slick-active').prev().addClass('prev-slide-item');
    $('.slick-active').next().addClass('next-slide-item');
})

$('.slider').slick({
    dots: true,
    infinite: true,
    speed: 300,
    adaptiveHeight: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    centerMode: true,
    variableWidth: true
});

//слайдер на странице стандарт

$('#slider-standard').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next')
});

// слайдеры на странице traverse

$('#slider-traverse-1').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    prevArrow: $('#slider_nav_1 .prev'),
    nextArrow: $('#slider_nav_1 .next')
});

$('#slider-traverse-2').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    prevArrow: $('#slider_nav_2 .prev'),
    nextArrow: $('#slider_nav_2 .next')
});

$('#slider-traverse-3').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    prevArrow: $('#slider_nav_3 .prev'),
    nextArrow: $('#slider_nav_3 .next')
});

//слайдер на странице авто с пробегом

$('.slider-advantages').slick({
    infinite: true,
    speed: 300,
    infinite: false,
    slidesToShow: 2,
    adaptiveHeight: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [
        {
            breakpoint: 991, // tablet breakpoint
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});

//footer dropdown menu

function collapseMenu() {
    var footer = document.querySelector('.footer');
    var menuItem = footer.querySelectorAll('.dropdown-menu');
    if ($(window).width() < 992) {
        for (var i = 0; i < menuItem.length; i++) {
            menuItem[i].classList.remove('show');
        }
    }
}

collapseMenu();

function collapseContacts() {
    var contactsItemText = document.querySelectorAll('.contacts__item-text-block');
    var contactsItem = document.querySelectorAll('.contacts__item');
    if ($(window).width() < 767) {
        contactsItem[0].classList.add('contacts__item--show');
        for (var i = 1; i < contactsItemText.length; i++) {
            contactsItemText[i].classList.add('contacts__item-text-block--hide');
        }
    }
}

collapseContacts();

function detectSafari() {
    if(navigator.vendor.match(/apple/i)) {
        var inputPlaceholder = $('.faq-main .form-control');
        inputPlaceholder.addClass('safari-placeholder');
    }
}

detectSafari();

function detectIE() {
    var mainMenu = $('.navbar-collapse__wrapper');
    var mainNav = $('.navbar-nav');
    var actionItem = $('.actions-item__more-btn');
    var newsImg = $('.news-item__img');
    var footerDropdown = $('.dropdown-menu--footer');
    var navItem = $('.nav-item:first-child');
    var dropdownModels = $('.dropdown-menu__item-wrapper--models');
    var dropdownCarImg = $('.dropdown-item--car > img');
    var subMenuDropdown = $('.dropdown-menu--submenu');
    var dropdownMenuModels = $('.dropdown-menu__item-wrapper--models');
    var usedCarImage = $('.car-item-used__img');
    if (navigator.appName == 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1)) {
        actionItem.css({'position': 'relative', 'bottom': '32px'});
        mainMenu.css({'margin-left': '100px'});
        if ($(window).width() < 1280 && $(window).width() > 992) {
            mainNav.css({'position': 'relative', 'right': '-10px'});
        }

        if ($(window).width() < 992) {
            mainMenu.css('height', '500px')
            newsImg.css('transform', 'scale(1)');
        }
        if ($(window).width() >= 768) {
            navItem.css({'width': 'auto'});
            footerDropdown.css({'position': 'relative', 'top': '0px'});
            dropdownModels.css({'flex-direction': 'column', 'width': '180px'});
            dropdownCarImg.css({'display': 'none'});
        }

        subMenuDropdown.css({'flex-direction': 'column'});
        dropdownMenuModels.css({'flex-direction': 'row', 'width': '350px'});
        usedCarImage.css({'width': '200px'});
    }
}

detectIE();

// Закрытие меню по клику на overlay
function overlayClick() {
    var overlay = $('#overlay');
    var menu = $('.navbar-collapse');
    var arrows = document.querySelectorAll('.nav-arrow');
    overlay.click(function () {
        $(".navbar-toggler").attr('aria-expanded', 'false');
        $('.navbar-toggler-icon--close').css('display', 'none');
        $('.navbar-toggler-icon--burger').css('display', 'block');
        menu.toggleClass('show');
        overlay.removeClass('overlay--active');
        $('.body-wrapper').removeClass('scroll-disable');
        $('html').removeClass('html-scroll-disable');
        for (var j = 0; j < arrows.length; j++) {
            arrows[j].classList.remove('nav-arrow--open');
        }
    })
}

overlayClick();


function loneBreadcrumb() {
    var breadcrumbs = $('.breadcrumbs__item');
    if (breadcrumbs.length == '1') {
        $('.breadcrumbs__item').addClass('lone-item');
    }
}

loneBreadcrumb();

// показать все элементы списка
function showAll() {
    $('.show-all').click(function () {
        $('.car-options .list-unorder li:nth-child(n+10)').css({'height': 'auto', 'opacity': '1'});
        $(this).css('display', 'none');
        $('.hide-all').css('display', 'block');
    })
    $('.hide-all').click(function () {
        $('.car-options .list-unorder li:nth-child(n+10)').css({'height': '0', 'opacity': '0'});
        $(this).css('display', 'none');
        $('.show-all').css('display', 'block');
        $('html,body').animate({
            scrollTop: $(".car-options").offset().top
        }, 500)
    })

}

showAll();

// показать все примечания на странице модели
function showNotes() {
    $('#additional .show-all--add').click(function () {
        $('#list-notes').css({'height': 'auto', 'opacity': '1', 'visibility': 'visible'});
        $(this).css('display', 'none');
        $('#additional .hide-all--add').css('display', 'block');
    })
    $('#additional .hide-all--add').click(function () {
        $('#list-notes').css({'height': '0', 'opacity': '0', 'visibility': 'hidden'});
        $(this).css('display', 'none');
        $('#additional .show-all--add').css('display', 'block');
        $('html,body').animate({
            scrollTop: $(".show-all--add").offset().top
        }, 500)
    })
}

showNotes();

function hideContent() {
    $('.show-content').click(function () {
        $('#list-notes').css({'height': 'auto', 'opacity': '1', 'visibility': 'visible'});
        $(this).css('display', 'none');
        $('.hide-content').css('display', 'block');
    })
    $('.hide-content').click(function () {
        $('#list-notes').css({'height': '0', 'opacity': '0', 'visibility': 'hidden'});
        $(this).css('display', 'none');
        $('.show-content').css('display', 'block');
        $('html,body').animate({
            scrollTop: $(".show-content").offset().top
        }, 500)
    })
}

hideContent();

$(document).ready(function () {
    $('.cars__list').css('opacity', '1');
})

function searchManager() {
    var filterDivision = $("#filter-division"),
        listManager = $(".list-manager .column");
    filterDivision.change(function () {
        listManager.each(function (index, element) {
            $(element).hide();
            if (Number(filterDivision.val()) === $(element).data('division') || Number(filterDivision.val()) === 0) {
                $(element).show();
            }
        })
    })
};

searchManager();

$('input[type="text"]').blur(function () {
    setTimeout(function () {
        if (!$(document.activeElement).is('input[type="text"]')) {
            $(window).scrollTop(0, 0);
        }
    }, 0);
});

$('input[type="tel"]').blur(function () {
    setTimeout(function () {
        if (!$(document.activeElement).is('input[type="text"]')) {
            $(window).scrollTop(0, 0);
        }
    }, 0);
});

$('input[type="email"]').blur(function () {
    setTimeout(function () {
        if (!$(document.activeElement).is('input[type="text"]')) {
            $(window).scrollTop(0, 0);
        }
    }, 0);
});

function dropdownMenu() {
    var dropdownItems = $('.dropdown-toggle__hidden');
    $(window).on('load', function () {
        if ($(window).width() < '992') {
            dropdownItems.each(function (index, elem) {
                if ($(elem).siblings('.dropdown-menu').length) {
                    $(elem).addClass('dropdown-toggle');
                    $(elem).data('toggle', 'dropdown').attr('data-toggle', 'dropdown');
                } else {
                    $(elem).addClass('arrow-hidden');
                }
            });
        }
    });
}

dropdownMenu();

function hoverMenu() {
    var menuItems = $('.-ie .dropdown-model');
    $(window).on('load', function () {
        if ($(window).width() > '991') {
            menuItems.on('mouseenter', function () {
                $(this).find('.dropdown-menu').css({'display': 'flex'});
                $(this).find('.car-img').css({'display': 'block'});
            });
            menuItems.on('mouseleave', function (e) {
                $(this).find('.dropdown-menu').hide();
            });
        }
    });
}

hoverMenu();

(function hideEmptyActions() {
    var actionsHeader = $('.model-actions-title-wrapper');
    var actionsWrapper = $('.model-actions-wrapper');
    var actionsList = $('.actions__list li');

    if(actionsList.length == 0){
        actionsHeader.hide();
        actionsWrapper.hide();
    }
})();

$(function () {
    $('.filter').ikSelect();
});

//скрытие хедера при скроле на мобильных экранах

function hideHeaderOnScroll() {
    var prevScrollpos = window.pageYOffset;;
    window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("navbar").style.top = "0px";
        }
        else {
            if (currentScrollPos > 70) {
                document.getElementById("navbar").style.top = "-62px";
            } else {
                document.getElementById("navbar").style.top = "0px";
            }
        }
        prevScrollpos = currentScrollPos;
    }
}

function fixedNavbar() {
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth < 769 && !window.matchMedia("(orientation: landscape)").matches) {
        hideHeaderOnScroll();
    } else {
        return false
    }
};
fixedNavbar();


// реинициализация скрытия хедера при изменении ориентации устройства

window.addEventListener("orientationchange", function() {
    hideHeaderOnScroll();

}, true);


(function showButton() {
    $(window).on('load', function () {
        $('.know-more-btn').css({'opacity': 1})
    });
})();

// скролл табов на мобильном на странице истории шевроле

(function() {
    if (window.location.href.indexOf("/about/istoriya/louis-chevrolet/") > 1) {
        var tabs = $('.tabs');
        if ($(window).width() < 768) {
            tabs.css({'flex-wrap': 'nowrap', 'overflow-x': 'auto', 'justify-content': 'space-between'})
        }
    }
})();