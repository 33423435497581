function isIE() {
	ua = navigator.userAgent;
	var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
	return is_ie;
}

var cardNumber = document.querySelector("body");

if (isIE()){
	cardNumber.classList.add("-ie");
}
