var WIN_CONTENT_CLASS = 'win_text_content';

/*fw_Win_active - instance активного попапа*/
var fw_Win_active;

var _FORMS = function () {
	var _FORMS = {
		controllers: {
			//1 Тест-драйв
			TEST_DRIVE_CONTROLLER: '/ajax/forms/getTestDriveForm/',
			//2 Расчет кредита
			CREDIT_CALC_CONTROLLER: '/ajax/forms/getCreditCalcForm/',
			//3 Обратная связь
			FEEDBACK_CONTROLLER: '/ajax/forms/getFeedBackForm/',
			//4 Индивидуальное предложение
			INDIVIDUAL_CONTROLLER: '/ajax/forms/getIndividualForm/',
			//5 Узнать больше об автомобиле (used)
			USED_CAR_CONTROLLER: '/ajax/forms/getCarUsedInfoForm/',
			CALL_BACK_CONTROLLER: '/ajax/forms/getCallBackForm/',
			//6 Задать вопрос (FAQ)
			FAQ_CONTROLLER: '/ajax/faq/getFaqForm/',
			AGREE_CONTROLLER: '/ajax/forms/getAgree/',
			POLICY_CONTROLLER: '/ajax/forms/getPolicy/',
			MAIN_PAGE_POLICY_CONTROLLER: '/ajax/forms/getMainPolicy/',

		},
		selectors: {
			//1 Тест-драйв
			TEST_DRIVE_SELECTOR: '#test_drive_place',
			TEST_DRIVE_MODEL_SELECTOR: 'select[name="model"]',
			//3 Обратная связь
			FEEDBACK_SELECTOR: '#feedback_place',
			//4 Индивидуальное предложение
			INDIVIDUAL_SELECTOR: '#individual_place',
			//6 Задать вопрос (FAQ)
			ESTIMATION_SELECTOR: '#estimation_place',
			FW_WIN_SELECTOR: '.fw_win_content'
		},
		title: {
			TEST_DRIVE_TITLE: 'Записаться на тест-драйв',
			CREDIT_CALC_TITLE: 'Расчет кредита',
			FEEDBACK_TITLE: 'Обратная связь',
			CALLBACK_TITLE: 'Заказать звонок',
			INDIVIDUAL_TITLE: 'Запросить индивидуальное предложение',
			USED_CAR_TITLE: 'Запрос подробной информации',
			ESTIMATION_TITLE: 'Оцените свой автомобиль',
			POLICY_TITLE: 'Политика безопасности',
			MAIN_PAGE_POLICY_TITLE: 'Политика безопасности',
			AGREE_TITLE: 'Согласие на обратную коммуникацию'
		},
		immediately: function () {
			$('.testdrive-form').click(function (e) {
				_FORMS.openTestDrive(this);
				fw_MAMMON.preventHref(e);
			});
			$('.credit-form').click(function (e) {
				_FORMS.openCreditCalc();
				fw_MAMMON.preventHref(e);
			});
			$('.feedback-form').click( function(event){
					link = $(this);
					_FORMS.openFeedBack(link);
					fw_MAMMON.preventHref(event);
			});
			$('.individual-form').click(function (e) {
				_FORMS.openIndividual();
				fw_MAMMON.preventHref(e);
			});
			$('.used-car-form').click(function(e){
				_FORMS.openCarUsedInfoForm();
				fw_MAMMON.preventHref(e);
			});
			$('.agree-open').click(function (e) {
				_FORMS.agreeOpenWin();
				fw_MAMMON.preventHref(e);
			});
			$('.faq-form').click(function(e){
				_FORMS.openFaqForm();
				fw_MAMMON.preventHref(e);
			});
			$('.call-back').click(function(e){
				_FORMS.openCallBack();
				fw_MAMMON.preventHref(e);
			});

			$('.call-main-page-policy').click(function(e){
				_FORMS.openMainPagePolicy();
				fw_MAMMON.preventHref(e);
			});

			if($(_FORMS.selectors.TEST_DRIVE_SELECTOR).length) {
				_FORMS.loadTestDrive(_FORMS.selectors.TEST_DRIVE_SELECTOR);
			}
			if($(_FORMS.selectors.FEEDBACK_SELECTOR).length) {
				_FORMS.loadFeedBack(_FORMS.selectors.FEEDBACK_SELECTOR);
			}
			if($(_FORMS.selectors.ESTIMATION_SELECTOR).length) {
				_FORMS.loadPageEstimationForm(_FORMS.selectors.ESTIMATION_SELECTOR);
			}
			if($(_FORMS.selectors.INDIVIDUAL_SELECTOR).length) {
				_FORMS.loadIndividual(_FORMS.selectors.INDIVIDUAL_SELECTOR, false);
			}
		},
		openTestDrive: function (obj) {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.TEST_DRIVE_TITLE,
				container_class: "test-drive-container",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var href = window.location.pathname;
			link_array = $(obj).attr("href");
			link_array = (link_array) ? link_array.split('#'):[];
			var model_code = (link_array.length > 1) ? link_array[1] : '';
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadTestDrive(to, model_code);
		},
		loadTestDrive: function (to, model_code) {
			fw_MOJAX.send({
				url: _FORMS.controllers.TEST_DRIVE_CONTROLLER,
				data: {model_code: model_code},
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function (json_data, user_data) {
					initIkSelectForm();
					phoneValidate();
					autocompleteOff();
					_FORMS.initTestDrivePicker();
					_FORMS.initModelSelectAction(to);
					_FORMS.selectModelInTestDriveForm(user_data.core_data.model_code,to);
					_FORMS.initAgreeWin(".agree-link");
					_FORMS.checkModelName();
					$(to).find('input[name=image]').val(model_image);
                    $('.safe_win').click(function(e){
                        _FORMS.policyOpenWin();
                        fw_MAMMON.preventHref(e);
                    });
				}
			});
		},

		checkModelName: function() {
			if ($('option[value="'+car_name+'"]').length) {
				$('select').ikSelect('select', car_name);
			}
		},

		selectModelInTestDriveForm: function (model_code,to) {
			var model_select = $(_FORMS.selectors.TEST_DRIVE_MODEL_SELECTOR);
			if (model_code) {
				model_select.ikSelect('select', model_select.find('option[data-model-code="' + model_code + '"]').index(), true);
			}
			else {
				model_select.ikSelect('select', model_select.find('option:first-of-type').index(), true);
			}
			_FORMS.setModelSelectImage(to);
		},
		setModelSelectImage: function (parent) {
			var model_id = $(parent).find('select[name=model] option:selected').data('id');
			$(parent).find('.model_info_item').hide();
			$(parent).find('.model_info_item[data-model-id=' + model_id + '] ').show();
			$(parent).find('input[name=image]').val($('.model_info_item[data-model-id=' + model_id + '] img').attr('src'));
		},
		initModelSelectAction: function (to) {
			$('select[name=model]').change(function () {
				_FORMS.setModelSelectImage(to);
			});
			_FORMS.setModelSelectImage(to);
		},
		initTestDrivePicker: function () {
			var inst = $('input[name=time]');
			var timePicker = inst.timepicker({
				defaultTime: "13:00",
				useBackground: false,
				countColumn: 2,
				orientation: 't',
				beforeShow: function(){
					$('.ftype_time input').parent().append($('.container_timepicker'));
				}
			});
			var icon = _FORMS.getInputIcon(inst);
			var date = new Date();
			date.setDate(date.getDate() + 1);
			if (icon.length) {
				icon.click(function (e) {
					!timePicker.isVisible() ? timePicker.show() : timePicker.hide();
					fw_MAMMON.preventDefaultSafe(e);
				});
			}
			_FORMS.initDatePicker(date, timePicker);
		},
		initDatePicker: function (default_date, timePicker) {
			var inst = $('input[name=date]');
			var icon = _FORMS.getInputIcon(inst);
			inst.datepicker({
				inline: true,
				minDate: new Date(),
				beforeShow: function(input,obj){
					$(input).after($(input).datepicker('widget'));
					$(input).addClass('active');
				},
				onClose: function(input, obj){
					$(obj.input).removeClass('active');
				},
				onSelect: function (dateText, inst) {
					$(this).trigger('change');
					if (!timePicker) return true;
					var selected_date = Date.parse(dateText.split('.').reverse().join('.')),
						date_obj = new Date(),
						current_date = Date.parse((date_obj.getMonth() + 1) + '/' + date_obj.getDate() + '/' + date_obj.getFullYear());
					if (selected_date === current_date) {
						var minutes = date_obj.getMinutes(),
							time = (date_obj.getHours() + 1) + ':' + (minutes < 10 ? '0' : '') + minutes;
						timePicker.disabledUntilTime(time);
					}
					else {
						timePicker.clearDisabled();
					}
					return true;
				}
			});
			if (icon.length) {
				icon.click(function (e) {
					if (inst.datepicker('widget').is(':hidden')) {
						inst.datepicker('show');
					}
					fw_MAMMON.preventDefaultSafe(e);
				});
			}

			if (default_date != 'undefined') {
				inst.datepicker('setDate', default_date);
				inst.datepicker("option", "dateFormat", "dd.mm.yy");
			}

			if(fw_MAMMON.isMobileDevice()) {
				$('.ftype_date input,.ftype_time input').focus(function () {
					$(this).blur();
				});
			}
		},
		getInputIcon: function (input) {
			return input.parent().find('.icon-input');
		},
		openCreditCalc: function () {
			fw_Win_active = fw_WIN.create({
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.CREDIT_CALC_TITLE,
				container_class: "credit-form-container",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadCreditCalc(to);
		},
		loadCreditCalc: function (to) {
			fw_MOJAX.send({
				url : _FORMS.controllers.CREDIT_CALC_CONTROLLER,
				to : to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function() {
					initIkSelectForm();
					phoneValidate();
					autocompleteOff();
					initCloseWindowButton();
					_CREDIC_CALC.initCreditCalc({calcButtonSelectText: '#calc_credit_button'});
					$(to).find('input[name=model]').val(model_name);
					$(to).find('input[name=integration]').val(model_name);
					$(to).find('input[name=price]').val(price);
					$(to).find('.credit_form_car_info .car_name').html(model_name);
					$(to).find('.credit_form_car_info .car_info_credit_price .numeral').html(text_price);
					$(to).find('.credit_form_car_info .car_info_image').attr('src', model_image);
					_FORMS.initAgreeWin(".agree-link");
					$('.safe_win').click(function(e){
						_FORMS.policyOpenWin();
						fw_MAMMON.preventHref(e);
					});
				}
			});
		},
		openFeedBack: function (link) {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.FEEDBACK_TITLE,
				container_class: "feedback-form-container",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadFeedBack(to,link);
		},
		loadFeedBack: function(to,link) {
			fw_MOJAX.send({
				url: _FORMS.controllers.FEEDBACK_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					initIkSelectForm();
					phoneValidate();
					autocompleteOff();
					// selectCurrentSalon(to,link);
					_FORMS.initAgreeWin(".agree-link");
					$('.safe_win').click(function(e){
						_FORMS.policyOpenWin();
						fw_MAMMON.preventHref(e);
					});
				}
			});
		},

		openCallBack: function (link) {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.CALLBACK_TITLE,
				container_class: "call-back-container",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadCallBack(to,link);
		},

		loadCallBack: function(to,link) {
			fw_MOJAX.send({
				url: _FORMS.controllers.CALL_BACK_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					initIkSelectForm();
					phoneValidate();
					autocompleteOff();
					selectCurrentSalon(to,link);
					to.find('.form_submit_button .text').text('Отправить');
					_FORMS.initAgreeWin(".agree-link");
					$('.safe_win').click(function(e){
						_FORMS.policyOpenWin();
						fw_MAMMON.preventHref(e);
					});
				}
			});
		},

		openIndividual: function() {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.INDIVIDUAL_TITLE,
				container_class: "individual-container",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			},{
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadIndividual(to, true);
		},
		loadIndividual: function(to, isForm) {
			fw_MOJAX.send({
				url: _FORMS.controllers.INDIVIDUAL_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					maskPhone();
					phoneValidate();
					autocompleteOff();
					initCloseWindowButton();
					_FORMS.initAgreeWin(".agree-link");
					$('.safe_win').click(function(e){
						_FORMS.policyOpenWin();
						fw_MAMMON.preventHref(e);
					});
					if(isForm){
						putIndividualInfo(_FORMS.selectors.FW_WIN_SELECTOR);
						randomOidTerritory(_FORMS.selectors.FW_WIN_SELECTOR);
					} else {
						randomOidTerritory(_FORMS.selectors.INDIVIDUAL_SELECTOR);
						$(_FORMS.selectors.INDIVIDUAL_SELECTOR + ' .form_inner_wrapper').prepend('<h2>Запросить индивидуальное предложение</h2>');
						$('.individual_form_info ').hide();
						_FORMS.initModelSelectAction();
					}
				}
			});
		},
		openCarUsedInfoForm: function() {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.USED_CAR_TITLE,
				container_class: "car-used-info-container",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadCarUsedInfoForm(to);
		},
		loadCarUsedInfoForm: function(to) {
			fw_MOJAX.send({
				url : _FORMS.controllers.USED_CAR_CONTROLLER,
				to : to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function() {
					initGetCarInfoForm(to);
					initIkSelectForm();
					phoneValidate();
					autocompleteOff();
					_FORMS.initAgreeWin(".agree-link");
					$('.safe_win').click(function(e){
						_FORMS.policyOpenWin();
						fw_MAMMON.preventHref(e);
					});
				}
			})
		},
		openFaqForm: function () {
			fw_Win_active = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				width: 470,
				title: "Задать вопрос",
				container_class: "faq-form-container",
				content_class: WIN_CONTENT_CLASS,
				without_padding: true
			});
			var to = $(_FORMS.selectors.FW_WIN_SELECTOR);
			_FORMS.loadFaqForm(to);
		},
		loadFaqForm: function (to) {
			fw_MOJAX.send({
				url: _FORMS.controllers.FAQ_CONTROLLER,
				to: to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function () {
					initIkSelectForm();
					_FORMS.initAgreeWin(".agree-link");
					$('.safe_win').click(function(e){
						_FORMS.policyOpenWin();
						fw_MAMMON.preventHref(e);
					});
				}
			});
		},

		loadPageEstimationForm: function(to) {
			fw_MOJAX.send({
				url : '/ajax/forms/getEstimationForm/',
				to : to,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				overlay_to: to,
				user_success: function(){
					$(_FORMS.selectors.ESTIMATION_SELECTOR).find('.title').text(_FORMS.title.ESTIMATION_TITLE);
					initIkSelectForm();
					phoneValidate();
					autocompleteOff();
					_FORMS.loadEstimationModels();
					_FORMS.initAgreeWin(".agree-link");
					$('.safe_win').click(function(e){
						_FORMS.policyOpenWin();
						fw_MAMMON.preventHref(e);
					});
				}
			});
		},

		initAgreeWin: function (agree_class) {
			$(agree_class).off().click(function (e) {
				_FORMS.agreeOpenWin();
				fw_MAMMON.preventHref(e);
			});
			window.inited = true;
		},

		agreeOpenWin: function (){
			agree_window = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.AGREE_TITLE,
				container_class: "form_inner_wrapper form-agree-container",
				content_class: WIN_CONTENT_CLASS + ' form_inner_wrapper form-agree',
				without_padding: true
			});
			fw_MOJAX.send({
				to: agree_window.content,
				url: _FORMS.controllers.AGREE_CONTROLLER,
				overlay_to: agree_window.content,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY
			});
		},

		policyOpenWin: function (){
			agree_window = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.POLICY_TITLE,
				container_class: "form_inner_wrapper policy_form",
				content_class: WIN_CONTENT_CLASS + ' form_inner_wrapper form-agree',
				without_padding: true
			});
			fw_MOJAX.send({
				to: agree_window.content,
				url: _FORMS.controllers.POLICY_CONTROLLER,
				overlay_to: agree_window.content,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY
			});
		},

		openMainPagePolicy: function (){
			agree_window = fw_WIN.create({
				lock_page: false,
				block_mode: true,
				offset: 0,
				content: '',
				title: _FORMS.title.MAIN_PAGE_POLICY_TITLE,
				container_class: "form_inner_wrapper policy_form",
				content_class: WIN_CONTENT_CLASS + ' form_inner_wrapper form-agree',
				without_padding: true
			});
			fw_MOJAX.send({
				to: agree_window.content,
				url: _FORMS.controllers.MAIN_PAGE_POLICY_CONTROLLER,
				overlay_to: agree_window.content,
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY
			});
		},

		loadEstimationModels: function(){
			fw_MOJAX.send( {
				url : "/ajax/cars/getBrandsListForSelect/",
				data: {},
				to: "select[name=brand]",
				overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
				user_complete: function() {
					$('select[name=brand]').ikSelect('reset').ikSelect('redraw');
					$('select[name=brand]').closest('.field_wrapper').addClass('has_val');
				}
			} );
			$('select[name=brand]').change(function() {
				fw_MOJAX.send( {
					url : "/ajax/cars/getModelsListForSelect/",
					data: { "brand":$(this).val() },
					to: "select[name=model]",
					overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
					user_complete: function() {
						$('select[name=model]').ikSelect('reset').ikSelect('redraw');
					}
				} );
			} );
		}
	};
	_FORMS.immediately();
	return _FORMS;
}();

function initGetCarInfoForm(parent){
	$(parent).find('input[name=model_name]').val(model_name);
	$(parent).find('input[name=car]').val(model_short_name);
	$(parent).find('input[name=price_clear]').val(price_clear);
	$(parent).find('input[name=TextYear]').val(year);
	$(parent).find('input[name=TextRun]').val(run);
	$(parent).find('input[name=territory_oid]').val(territory_oid);

	$(parent).find('.form-car-info-used .car-name').text(model_name);
	$(parent).find('.form-car-info-used .car-price').html(text_price);
	$(parent).find('.form-car-info-used .car-image').attr('src', model_img_src);
}
function selectCurrentSalon(parent, link){
	if (link) {
		var salon_id = link.data('salon-id');
		if (!(salon_id==''||salon_id==undefined)){
			if ($(parent).find('select[name=territory_oid] option[value='+salon_id+']').length !=0){
				$(parent).find('select[name=territory_oid]').val(salon_id);
				$(parent).find('select[name=territory_oid]').ikSelect('reset').ikSelect('redraw');
			}
		}
	}
}
function putIndividualInfo(parent){
	// $(parent).find('input[name=TextBrand]').val(brand_name_individ);
	$(parent).find('input[name=model_name]').val(model_name_individ);
	$(parent).find('input[name=model_price]').val(price_individ);
	$(parent).find('input[name=order_num]').val(order_num_individ);
	$(parent).find('select[name=model] option:selected').removeAttr('selected');
	//TODO: проверить будет ли работать selected
	$(parent).find('select[name=model] option[data-model_id=' + model_id_individ + ']').attr('selected','selected');
	$(parent).find('select[name=model]').parents('.field_wrapper').hide();
	$(parent).find('select[name=url]').parents('.field_wrapper').hide();
	$(parent).find('.individual_form_info .car-name').text(model_name_individ);
	console.log(model_name_simple_individ);
	$(parent).find('.individual_form_info .car-price').html(text_price_individ + ' руб.');
	$(parent).find('.individual_form_info .car-image').attr('src', model_big_image_individ);
}
function randomOidTerritory(parent) {
	var countTerritory = $(parent).find('select[name=territory_oid] option').length;
	if(countTerritory > 1) {
		var rand = Math.floor(Math.random() * countTerritory);
		$(parent).find('select[name=territory_oid] option:selected').removeAttr('selected');
		var val =  $(parent).find('select[name=territory_oid] option').eq(rand).val();
		$(parent).find('select[name=territory_oid]').val(val);
		$(parent).find('select[name=territory_oid]').ikSelect('reset').ikSelect('redraw');
	}
	else{
		$(parent).find('select[name=territory_oid]').parents('.field_wrapper ').hide();
	}
}
function initIkSelectForm() {
	$('select.form-control').ikSelect({
		autoWidth: false,
		ddFullWidth: false,
		ddCustomClass: "custom_dd",
		linkCustomClass: "form-control",
		ddMaxHeight: 300,
		onShow: function (inst) {
			inst.$link.addClass("active");
			inst.$link.addClass('hover');
			$(inst.$el).after(inst.$dropdown);
		},
		onHide: function (inst) {
			inst.$link.removeClass("active");
			inst.$link.removeClass('hover');
		}
	});
	maskPhone();
}

function initCloseWindowButton() {
	$('body').on('click', '.close_window_button', function (event) {
		$(this).closest('.fw_win_wrapper').find('.fw_win_close_button').trigger('click');
		fw_MAMMON.preventHref(event);
	});
}

function maskPhone() {
	return $('input[name=phone], input[name=tele_hack], input[type=tel]').mask("+7(999) 999-99-99");
}

function phoneValidate() {
	let inputPhone = $("[name='mobile_phone'], [name='home_phone'], input[type='tel'], input[name='phone'], input[name='tele_hack']");

	inputPhone.on('keydown', function (e) {
		let _this = $(this),
				sub_string = '';
		sub_string = _this.val().substring(3, 4);
		if (sub_string !== "9") {
			inputPhone.addClass('formjor-err');
			_this.parents('.field_wrapper').addClass('formjor-err');
		} else {
			_this.removeClass('formjor-err');
			_this.parents('.field_wrapper').removeClass('formjor-err');
		}
	});

	inputPhone.on('blur', function () {
		let _this = $(this),
				sub_string = _this.val().substring(3, 4);
		if (_this.val().length > 0) {
			if(_this.val().length !== 17 || sub_string !== "9"){
				_this.addClass('formjor-err');
				_this.parents('.field_wrapper').addClass('formjor-err');
			} else {
				_this.removeClass('formjor-err');
				_this.parents('.field_wrapper').removeClass('formjor-err');
			}
		}
	});

}

function autocompleteOff() {
	let form = $('.form_style');
	let input = $('.form_style input, .form-control input');

	form.attr('autocomplete', 'off');
	input.attr({
		autocomplete: 'off',
		role: 'presentation'
	});

}