/**
 * Управлялка формами.
 *
 * Использование: $('#someform').formjor({
*	ERROR_CLASS : 'имя класса ошибки',		// без ведущей точки
*	fields : 'имена полей для валидации',	//через запятую, по дефолту - все
*	field_params : {						//параметры валидации
*	    'input_name' : {
* 			id : 'string pointer',
* 			pattern : 'regexp',
* 			message : 'error message',
*			type : 'HttpDataLib type'
* 		}...
*	},
*	to : '#some_container',					//блок, куда вставлять content
*	action : '/куда/отправлять/аякс/'
* })
 *
 * Все параметры можно устанавливать через data- атрибут тэга формы ДО инита плагина (data-action="/some/action/").
 * После инита чистится вся data и поменять параметры можно только через сеттеры (functions): $('#someform').formjor('setAction', '/some/action/').
 *
 * По мере работы плагина всплывают различные ивенты (все заканчиваются на .formjor), this = jQuery form object:
 * 	init.formjor - Конец инита формы
 * 		(без параметров)
 * 	invalid.formjor - на каждое поле, провалившее валидацию
 * 		(jQuery input object, input params)
 * 	valid.formjor - перед отправкой аякса
 * 		(string fields, object all field params)
 * 	fail.formjor - провал валидации на сервере
 * 		(server json response)
 * 	success.formjor - корректный ответ
 * 		(server json response)
 * itemvalid.formjor - на каждое поле после submit'a, прошедшее валидацию
 *      (jQuery input object, input params)
 */
(function ($) {

	var plugin_name = 'formjor';
	var _defaults = {
		ERROR_CLASS : plugin_name+'-err',
		MESSAGE_ERROR_CLASS : plugin_name+'-message-err',
		fields : '',
		field_params : {},
		action : null,
		to : null,
		use_success_content: false,
		field_wrapper_class: 'field_wrapper'
	};
	var functions = {
		/** Убирает классы ошибок*/
		refresh: function(obj){
			var props = instances.get(obj);
			obj.find('.'+props.ERROR_CLASS).removeClass(props.ERROR_CLASS);
			obj.find('.'+props.MESSAGE_ERROR_CLASS).removeClass(props.MESSAGE_ERROR_CLASS);
			return obj;
		},
		/** обновляет псевдокапчу*/
		pseudocaptcharaize : function(obj){
			var props = instances.get(obj);
			for(var i in props.field_params){
				if(props.field_params.hasOwnProperty(i)){
					if($('#'+props.field_params[i].id+'[data-pcaptcha]').length > 0){
						$.ajax({
							type: "POST",
							url: '/pseudo_captcha.php',
							success: function(data){
								$('#'+props.field_params[i].id).attr('value',data);
							}
						});
						break;
					}
				}
			}
			return obj;
		},
		/** обновляет url*/
		updateUrl : function(obj){
			var props = instances.get(obj);
			for(var i in props.field_params){
				if(props.field_params.hasOwnProperty(i)){
					if($('#'+props.field_params[i].id+'[data-url]').length > 0){
						$('#'+props.field_params[i].id).val(document.location);
						break;
					}
				}
			}
			return obj;
		},
		updateReferer : function(obj){
			var props = instances.get(obj);
			for(var i in props.field_params){
				if(props.field_params.hasOwnProperty(i)){
					if($('#'+props.field_params[i].id+'[data-referer]').length > 0){
						$('#'+props.field_params[i].id).val($('meta[name=page_referer]').attr("content"));
						break;
					}
				}
			}
			return obj;
		},
		/**
		 * Куда отправлять аякс
		 * @param obj jquery form object
		 * @param new_action action to go on submit ('/ajax/forms/execForm/1/')
		 * @returns obj
		 */
		setAction : function(obj, new_action){
			instances.data[obj.data(plugin_name)].action = new_action;
			return obj;
		},
		/**
		 * Добавляет поле в массив валидаций
		 * @param obj jquery form object
		 * @param new_fields array of plane objects [{
		 * 		'input_name' : {
		 * 			id : 'string pointer',
		 * 			pattern : 'regexp',
		 * 			message : 'error_message',
		 *			type : 'HttpDataLib type'
		 * 		}
		 * 	}]
		 * @returns obj
		 */
		setFields : function(obj, new_fields){
			var inst = obj.data(plugin_name),
				i,
				fields = [],
				params = {};
			for (i in new_fields){
				fields.push(i);
				params[i] = new_fields[i];
			}
			instances.data[inst].fields = fields.join(',');
			$.extend(instances.data[inst].field_params, params);
			obj.on('focusout change', getFieldsIds(new_fields).join(','), fieldHandler);
			return obj;
		},
		/**
		 * Геттер. Отдает все или только указанное поле.
		 */
		getProperties : function(obj, param){
			return param == undefined ? instances.get(obj) : instances.get(obj)[param];
		}
	};

	var instances = {
		data : [],
		get : function(obj){
			return instances.data[obj.data(plugin_name)] || false;
		},
		set : function(obj, params){
			var inst;
			if(obj.data(plugin_name)){
				inst = obj.data(plugin_name);
			}else{
				instances.data.push([]);
				inst = instances.data.length - 1;
				obj.data(plugin_name, inst);
			}
			instances.data[inst] = params;
		}
	};

	var fieldHandler = function(){
		var item = $(this),
			form = item.closest('form'),
			properties = instances.get(form),
			user_fields = getUserFields(properties.fields);
		$('label[for='+item.attr('id')+']').removeClass(properties.ERROR_CLASS);
		$('*[data-field-id="'+item.attr('id')+'"]').removeClass(properties.MESSAGE_ERROR_CLASS);
		$('*[data-formjor-field-id="'+item.attr('data-formjor-field-id')+'"]').removeClass(properties.ERROR_CLASS);
		item.closest('label').removeClass(properties.ERROR_CLASS);
		item.removeClass(properties.ERROR_CLASS);
		item.closest('.'+properties.field_wrapper_class).removeClass(properties.ERROR_CLASS);
		if(!user_fields || !!user_fields[item.attr('name')] && !!properties.field_params[item.attr('name')]){
			var value = item.val();
			if(item.is(':radio'))
			 	value = item.filter(':checked').val();
			else if(item.is(':checkbox'))
			 	value = item.is(':checked') ? 'on' : undefined;
			if(fw_MAMMON.validate(value, properties.field_params[item.attr('name')])){
				form.trigger('invalid.formjor', [item, properties.field_params[item.attr('name')]]);
			}
			else {
				form.trigger('itemvalid.formjor',[item, properties.field_params[item.attr('name')]]);
			}
		}
	};

	function getArrayByKeys(fields, keys){
		if(!keys) return fields;
		var ret = [], i;
		for(i in fields){
			if(keys.hasOwnProperty(i))
				ret.push(fields[i]);
		}
		return ret;
	}

	function getUserFields(fields){
		if(fields == '') return false;
		var ret = [],
			i, field;
		fields = fields.split(',');
		for (i = 0; i < fields.length; i++){
			field = $.trim(fields[i])
			ret[field] = field;
		}
		return ret;
	}

	function getFieldsIds(list){
		var ids = [], i;
		for(i in list){
			ids.push('*[data-formjor-field-id="'+list[i].id+'"]');
		}
		return ids;
	}

	$.fn.formjor = function (options, param) {
		if(typeof options == 'string'){
			if(functions[options] !== undefined){
				return functions[options](this, param);
			}else{
				console.error('FORMJOR: method "'+func+'" is not defined');
				return false;
			}
		}
		options = $.extend({}, _defaults, {action : this.attr('action')}, this.data(), options);
		this.removeData();
		instances.set(this, options);
		functions.pseudocaptcharaize(this);
		functions.updateReferer(this);
		functions.updateUrl(this);
		var ids = getFieldsIds(options.field_params);
		this.trigger('init.formjor');
		return this.on('submit', function(e){
			var form = $(this),
				errors,
				properties = instances.get(form),
				user_fields = getUserFields(properties.fields);
			functions.refresh(form);
			errors = fw_MAMMON.validateAll(fw_MAMMON.getObjFromPost(form.serializeArray()), getArrayByKeys(properties.field_params, user_fields));
			if(errors){
				for(var i in errors){
					form.trigger('invalid.formjor', [$('*[data-formjor-field-id="'+errors[i].id+'"]'), errors[i]]);
				}
			}else{
				form.trigger('valid.formjor', [properties.fields, properties.field_params]);
				if(properties.action){
					try {
						var form_data = fw_MAMMON.serializePost(form);
						var key = 'chevrolet_nr';
						var phone = form.find('input[name=phone]').val().replace(/[\D\+]/g, "").replace(/^8/, "7");
						var textmodel = form.find('input[name=textmodel]').val();
						var name = form.find('input[name=name]').val();
						var info = [
							{
								"name": "Имя",
								"value": name == undefined ? 'no' : name
							},
							{
								"name": "Модель",
								"value": textmodel == undefined ? 'no': textmodel
							}
						];
						form_data.forEach(function (element) {
							if (element.name == "form_name") {
								info.push({
									"name": "Форма",
									"value": element.value
								});
							}
						});
						window.ctw.createRequest(
								key,
								phone,
								info,
								function (success, data) {
									if (success) {
										form_data.forEach(function (element) {
											if (element.name == "is_ct_success") {
												element.value = data.callbackRequestId
											}
										});
										sendMjrForm(form_data);
									} else {
										switch (data.type) {
											case "request_throttle_timeout":
											case "request_throttle_count":
												console.log('Достигнут лимит создания заявок, попробуйте позже');
												break;
											case "request_phone_blacklisted":
												console.log('номер телефона находится в черном списке');
												break;
											case "validation_error":
												console.log('были переданы некорректные данные');
												break;
											default:
												console.log('Во время выполнения запроса произошла ошибка: ' + data.type);
										}
										sendMjrForm(fw_MAMMON.serializePost(form));
									}
								}
						)
					} catch (err) {
						console.log('call touch error');
						console.log(err);
						sendMjrForm(fw_MAMMON.serializePost(form));
					}
					function sendMjrForm(data) {
						fw_MOJAX.send({
							to: properties.to || (properties.use_success_content ? form : false),
							url: properties.action,
							data: data,
							overlay_mode: fw_MOJAX.BLOCK_OVERLAY,
							overlay_to: properties.to || form,
							user_fail: function (json) {
								functions.pseudocaptcharaize(form);
								form.trigger('fail.formjor', [json]);
								if (json && json.errors) {
									for (var i in json.errors) {
										form.trigger('invalid.formjor', [$('#' + json.errors[i].id), json.errors[i]]);
									}
								}
							},
							user_success: function (json) {
								form.trigger('success.formjor', [json]);
							}
						});
					}
				}
			}
			e.stopImmediatePropagation();
			e.preventDefault();
			return false;
		}).on('change onSelectTime', ids.join(','), fieldHandler)
			.on('invalid.formjor', function(e, target, input_params){
				var properties = instances.get($(this));
				$('label[for='+target.attr('id')+']').addClass(properties.ERROR_CLASS);
				$('*[data-field-id="'+target.attr('id')+'"]').addClass(properties.MESSAGE_ERROR_CLASS);
				$(target).closest('label').addClass(properties.ERROR_CLASS);
				target.addClass(properties.ERROR_CLASS);
				$(target).closest('.ik_select').addClass(properties.ERROR_CLASS);
				$(target).closest('.'+properties.field_wrapper_class).addClass(properties.ERROR_CLASS);
			});
	};

})(jQuery);