function accordion() {
	$('.toggle').click(function(e) {
		e.preventDefault();

		var $this = $(this);

		if ($this.next().hasClass('show')) {
			$this.next().removeClass('show');
			$('.open').removeClass('open');
			$this.removeClass('open');
			$this.next().slideUp(350);
		} else {
			$('.open').removeClass('open');
			$this.addClass('open');
			$this.parent().parent().find('li .inner').removeClass('show');
			$this.parent().parent().find('li .inner').slideUp(350);
			$this.next().toggleClass('show');
			$this.next().slideToggle(350);
		}
	});
}

accordion();